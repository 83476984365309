import React from 'react'
import styles from './Scheme.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/img_4.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_6 from '../../image/Deadlines/img_6.svg'

const Scheme = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }

    const cardAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .7}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Scheme' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Как заказать<span> аренду опалубки</span></motion.h2>
            <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЗАЯВКА</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по одному из телефонных номеров.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>консультация</h3>
                            <p className={styles.item_text}>Наш специалист уточняет все ваши пожелания(комплектация, сроки аренды, самовывоз или доставка), Консультирует по условиям аренды и доставки.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ПРЕДЛОЖЕНИЕ</h3>
                            <p className={styles.item_text}>Наши специалисты рассчитают количество оборудования, стоимость аренды опалубки. Подберут оптимальный вариант, соответствующий Вашим потребностям.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЗАКЛЮЧАЕМ ДОГОВОР</h3>
                            <p className={styles.item_text}>После согласования всех основных условий(комплектация, сроки, стоимость, самовывоз или доставка) и предоставления необходимой документации составляется договор. </p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Договор подписывают обе стороны и осуществляется предоплата/оплата за аренду конструкций на основании подписанных документов.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Отгрузка опалубки</h3>
                            <p className={styles.item_text}>Выбранное оборудование доставляется на объект в Москве или области, оформляется акт приемки-передачи оборудования. Вам также доступен и самовывоз.</p>
                        </motion.li>
                    </motion.ul>
                        <a 
                        className={styles.button} 
                        target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
            </div>   
        </motion.section>
        
        
    )
}

export default Scheme
