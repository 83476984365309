import React from 'react'
import styles from './Confidence.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}



const Confidence = () => {

    return (
        <motion.section 
            className={styles.main}
            id='Advantages'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}><span>6 ключевых преимуществ </span> по которым заказчики выбирают  нас</motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>01</p>
                            <p className={styles.item_title}>Все оперативно</p>
                        </div>
                        <p className={styles.item_text}><span>Процесс погрузки и доставки отработан годами и максимально ускорен.</span>  Теперь ваша заявка обрабатывается в кратчайшие сроки и Вы получите выбранный комплект опалубки не потеряв драгоценное время.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>02</p>
                            <p className={styles.item_title}>Собственный склад</p>
                        </div>
                        <p className={styles.item_text}><span>У нас есть собственный большой склад,</span> на котором хранится широкий ассортимент опалубки и комплектующих. Поэтому вам не придется ждать - все необходимое оборудование есть в наличии.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>03</p>
                            <p className={styles.item_title}>Официальный договор</p>
                        </div>
                        <p className={styles.item_text}>Для того, чтобы взять в аренду опалубку мы с Вами заключаем официальный договор, и после этого Вы можете произвести оплату безналичным способом. <span>Это делает взаимоотношения честными и безопасными.</span></p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>04</p>
                            <p className={styles.item_title}>Высокое качество</p>
                        </div>
                        <p className={styles.item_text}><span>Вся опалубка и комплектующие только проверенных известных производителей.</span> Опалубку ненадлежащего качества после цикла аренды - списываем, предлагая вам только самое лучшее. Вы сами можете проверить оборудование перед приемом.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>05</p>
                            <p className={styles.item_title}>Собственный транспорт</p>
                        </div>
                        <p className={styles.item_text}><span>У нас есть собственный парк автомобилей.</span> Мы можем доставить оборудование в срок с точностью до дня на ваш объект, что так же поможет вам сэкономить время.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>06</p>
                            <p className={styles.item_title}>Бесплатный рачет</p>
                        </div>
                        <p className={styles.item_text}>Наши инженеры рассчитывают необходимое количество арендуемой опалубки и комплектующих к ней конретно для Вашего проекта и подробно проконсультируют.</p>
                    </motion.li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default Confidence