import React from 'react'
import styles from './InitialPage.module.scss'

const InitialPage = () => {
    return (
        <section className={styles.main} id='initial'>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <p className={styles.text}>Нужна качественная и недорогая опалубка?</p>
                    <h1 className={styles.title}>АРЕНДА, продажа и покупка ОПАЛУБКИ в Москве и Подмосковье</h1>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Высокое качество и широкий размерный ряд опалубки</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Доставка в течение 1 дня после обращения в нашу компанию</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Огромный собственный склад опалубки для любых объектов</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>рассчитать стоимость</a>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage