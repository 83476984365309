import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'

const Feedback = () => {


    return (
        <section className={styles.main} id='Reviews'>
            <div className={styles.box}>
                <h2 className={styles.title}>Отзывы <span>наших клиентов</span></h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Анатолий Cody</p>
                                        <p className={styles.comment}>18.06.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Брал в аренду опалубку для монолитного перекрытия. Созвонился, скинул план перекрытия и сроки аренды. Ребята быстро посчитали, сделали раскладку и прислали ком.предложение. Цены очень демократичные, при необходимости оперативно подскажут и ответят на вопросы. С доставкой/возвратом проблем также нет, доставляют/забирают четко в оговоренный срок, с подписанием договоров и актов. Оборудование в хорошем состоянии, претензий не было. Всегда можно созвониться уточнить интересующие моменты. Сам очень доволен сотрудничеством и советую другим! </p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Дмитрий Владимирович</p>
                                        <p className={styles.comment}>30.05.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Строили дом с монолитным подвалом и двумя перекрытиями . Обратился в эту компанию , менеджер был Александр , очень быстро расчитал и отрисовал опалубку , цена по рынку ниже конкурентов . Быстро привезли , с возвратом все четко приняли , хоть мы немного и повредили стойки . Сотрудничеством доволен .</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>Игорь П.</p>
                                    <p className={styles.comment}>18.08.2022</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Арендовал опалубку для монолитного перекрытия. Доставка к месту стройки и прибытие машины для возврата очень пунктуальность. В процессе заказа все подробно объяснили, точно рассчитали и дали схему установки всех элементов. Возврат аванса в этот же день без каких либо эксцессов. Полностью доволен. Рекомендую.</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Михаил</p>
                                        <p className={styles.comment}>13.09.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Я строитель, меня зовут Михаил. Два года назад была задача быстро найти опалубку под залитие бетонных плит строящегося дома. Случайно обратился в данную компанию, был сразу порадовал оперативностью подсчёта и консультации, более того, чуть-чуть просчитался в стойках, ребята оперативно подвезли. С тех пор, сотрудничаю сними по всем объектам. искренне рекомендую</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Булат П.</p>
                                        <p className={styles.comment}>09.10.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Эти ребята - настоящие профи в своем деле! Уже года 3 сотрудничаем с ними по аренде опалубки и каждый раз остаемся довольны. Ребята оперативно привозят материалы, помогают с расчетами и всегда идут на встречу. Если решили строить что-то серьезное и нужна надежная компания для аренды или продажи опалубки - смело обращаетесь к ним.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Андрей П.</p>
                                        <p className={styles.comment}>10.10.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Брал опалубку для монолитных работ в частном доме - все отлично. Отзывчивый персонал, замечательный менеджер Трунин Александр. Большая,аккуратная база хранения, куда приятно приезжать. Неликвидную фанеру отдали без проблем, сами же и погрузили.Отрадно, что есть такие фирмы.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Георгий Кузя</p>
                                        <p className={styles.comment}>22.07.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Менеджер помог подобрать комплект опалубки по нашему запросу. Оперативно доставили до нас транспортом компании. Опалубка хорошего качества, без нареканий.Обратно возвращали на склад самостоятельно в выходные - оборудование приняли быстро, залог вернули. Офис и обслуживание приятное, склад просторный. Спасибо, рекомендуем!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}

export default Feedback