import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Services/img_1.jpg'
import image_2 from '../../image/Services/img_2.webp'
import image_3 from '../../image/Services/img_3.jpg'
import image_4 from '../../image/Services/img_4.jpg'
import image_5 from '../../image/Services/img_5.jpg'
import image_6 from '../../image/Services/img_6.jpg'
import image_7 from '../../image/Services/img_7.jpg'
import image_8 from '../../image/Services/img_8.jpg'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Services = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='Services' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Мы предлагаем <span>все виды опалубки </span></motion.h2>
        <motion.ul className={styles.list}>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_1} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Опалубка стен</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_2} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Опалубка колонн</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_3} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Опалубка фундамента</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_4} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Опалубка перекрытий</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_5} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Комплектующие к опалубке</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_7} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>объемная опалубка</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать опалубку</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_6} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>строительные леса</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать леса</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_8} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>ламинированная фанера</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79773901000' aria-label='связаться вацап'>заказать фанеру</a>
                </div>
            </motion.li>
        </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Services