import React from 'react'
import styles from './Types.module.scss'
import { motion } from "framer-motion"
import img_1 from '../../image/Types/img_1.png'
import img_2 from '../../image/Types/img_2.png'
import img_3 from '../../image/Types/img_3.png'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}



const Types = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Аренда опалубки <span> - это выгодно и удобно!</span></motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_1} alt='кровля'/>
                        <p className={styles.item_title}>Быстро и недорого</p>
                        <p className={styles.item_text}>Затраты на аренду в десятки раз ниже покупки и не нужно ждать месяц на производстве. Это позволяет быстро наращивать темпы строительства и масштабировать бизнес без больших вложений</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_3} alt='кровля'/>
                        <p className={styles.item_title}>Гибкие условия аренды</p>
                        <p className={styles.item_text}>Оплата аренды происходит помесячно, снижая финансовую нагрузку на бизнес. По сути, это вывод на аутсорсинг, в руки профессионалов одной из самых затратных статей бюджета строительства</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_2} alt='кровля'/>
                        <p className={styles.item_title}>Экономия на обслуживании</p>
                        <p className={styles.item_text}>После завершения аренды не надо складировать, охранять и обслуживать опалубку — а расходы по этому направлению в перерывах между объектами могут доходить до половины стоимости новой опалубки</p>
                    </motion.li>
                    
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default Types