import React from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.png'
import log from '../../image/Header/logo.png'
import ButtonBox from '../ButtonBox/ButtonBox'

const Header = ({isOpen}) => {

    return (
        <header className={header.header}>
        <div className={header.box}>
                <div className={header.info}>
                    <div className={header.box_logo}>
                        <a className={header.logo_link} href='initial'>
                            <img className={header.logo} src={logo} alt = 'логотип'/>
                            <img className={header.logo_min} src={log} alt = 'логотип'/>
                        </a>
                        <div className={header.contacts}>
                            <div className={header.box_social}>
                                <ButtonBox/>
                            </div>
                            <div className={header.box_phone}>
                                <a className={header.phone}  href="tel:+79773901000" target='_blank' rel='noopener noreferrer'>+7 977 <span>390-10-00</span></a>
                                <a className={header.phone}  href="tel:+79001400024" target='_blank' rel='noopener noreferrer'>+7 900 <span>140-00-24</span></a>
                            </div>
                        </div>
                        <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
                    </div>
                    <nav className={header.nav}>
                        <a className={header.link} href='#Services'>Услуги</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#Advantages'>Преимущества</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#Portfolio'>Портфолио</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#Scheme'>Схема работы</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#Reviews'>Отзывы</a>
                        <div className={header.line}></div>
                        <a className={header.link} href='#Kontakty'>Контакты</a>
                    </nav>
                </div>
        </div>
    </header>    
    );
};

export default Header