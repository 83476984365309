import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import ButtonBox from '../ButtonBox/ButtonBox'
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Footer/phone.svg'
import email from '../../image/Footer/email-icon.svg'
import address from '../../image/Footer/company.svg'
import time from '../../image/Footer/time.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            id='Kontakty'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Контакты</motion.h2>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79773901000" target='_blank' rel='noopener noreferrer'>+7 (977) 390-10-00</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+79001400024" target='_blank' rel='noopener noreferrer'>+7 (900) 140-00-24</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="mailto:tj-stroy@yandex.ru" target='_blank' rel='noopener noreferrer'>tj-stroy@yandex.ru</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={address} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>г. Москва, ул. Маршала Прошлякова, 5к4</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts_social}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Пн-Пт 8:00 - 20:00 Сб&#8205;-&#8205;Вс&#160;8:00&#160;-&#160;20:00</p>
                            <ButtonBox/>
                    </motion.div>
                </div>
                <motion.div 
                    className={styles.box_map}
                    variants={titleAnimation} 
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <DetailMap/>
                </motion.div>
            </motion.div>
        </motion.section>
    )
}

export default Map