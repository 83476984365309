import React from 'react'
import { motion } from "framer-motion"
import styles from './Questions.module.scss'
import Hint from '../../components/Hint/Hint'
import { arrQuestions } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


const Questions = () => {

    return (
        <motion.section
            id='Voprosy'
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                    <div className={styles.box}>
                        <div className={styles.container}>
                            <motion.h2 className={styles.title} variants={titleAnimation}>Часто задаваемые<span> вопросы</span> </motion.h2>
                            <motion.ul className={styles.list} variants={boxAnimation}>
                                    {arrQuestions.map(item  => {
                                        const keyUid = uuidv4();
                                        return(
                                            <Hint
                                                question={item.question}
                                                answer={item.answer}
                                                key={keyUid}
                                        />
                                        )
                                    }   
                                        )}
                            </motion.ul>
                        </div>
                    </div>
        </motion.section>
        
    )
}

export default Questions