import React from 'react'
import { motion } from "framer-motion"
import styles from './Ipoteka.module.scss'
import image from '../../image/Ipoteka/img.webp'
import disactive from '../../image/Ipoteka/check.svg'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}

const Ipoteka = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <div className={styles.box_text}>
                    <h2 className={styles.title}>Оставьте заявку и закажите консультацию</h2>
                    <div className={styles.box_pulse}>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                        <div className={styles.box_text}>
                                            <p className={styles.text}>Ответит на все Ваши вопросы</p>
                                        </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                        <div className={styles.box_text}>
                                            <p className={styles.text}>Предложит оптимальное решение</p>
                                        </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Проверит наличие конструкций</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Подготовит смету и коммерческое предложение</p>
                                    </div>
                                </div>
                            </div>
                    <button className={styles.button} type='button' onClick={isOpen}>Оставить заявку</button>
                </div>
                <div className={styles.box_image}>
                    <img className={styles.image} src={image} alt='мальчик'/>
                    <div className={styles.image_info}>
                        <h3 className={styles.image_title}>Олег Елин</h3>
                        <p className={styles.image_text}>старший менеджер</p>
                    </div>
                </div>
            </motion.div>
        </motion.section>
    )
}

export default Ipoteka