import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import Confidence from '../../components/Confidence/Confidence'
import Practical from '../../components/Practical/Practical'
import Feedback from '../../components/Feedback/Feedback'
import Services from '../../components/Services/Services'
import Scheme from '../../components/Scheme/Scheme'
import Types from '../../components/Types/Types'
import SliderAdvantages from '../../components/SliderAdvantages/SliderAdvantages'
import Map from '../../components/Map/Map'
import Callback from '../../components/Callback/Callback'
import Questions from '../../components/Questions/Questions'
import Portfolio from '../../components/Portfolio/Portfolio'
import Ipoteka from '../../components/Ipoteka/Ipoteka'

const Main = ({isImageOpen, isOpen, popupOpen }) => {

    return (
        <section className={styles.main}>
            <InitialPage/>
            <SliderAdvantages/>
            <Services/>
            <Confidence/>
            <Types/>
            <Ipoteka
                isOpen={isOpen}
            />
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Scheme/>
            <Feedback/>
            <Questions/>
            <Callback
                popupOpen={popupOpen}
            />
            <Practical/>
            <Map/>
        </section>
    )
}

export default Main